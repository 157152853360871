/* eslint-disable camelcase */

import React, { useState } from 'react'
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import AlertDynamic from '../../../components/AlertDynamic'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import {
  useGetuserDetailsQuery,
  useLoginMutation,
  useUpdateUserPassword
} from '../../../utils/api'
/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const MyAccountChangePasswordModal = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props

  // states
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  })
  const [errorMessage, setErrorMessage] = React.useState('')

  const [passwordState, setPasswordState] = React.useState({
    currentPassword: {
      type: 'password',
      show: false
    },
    newPassword: {
      type: 'password',
      show: false
    },
    newPasswordConfirm: {
      type: 'password',
      show: false
    }
  })
  const [isPasswordFormatRight, setIsPasswordFormatRight] =
    React.useState(true)
  const [isPasswordSameCheck, setIsPasswordSameCheck] = React.useState(true)
  // update keys for dynamic alerts
  const [update, setUpdate] = React.useState(0)
  // functions

  // react-query api
  const UpdateUserPassword = useUpdateUserPassword()
  const userDetailsQuery = useGetuserDetailsQuery()
  const LoginFunc = useLoginMutation()

  // TODO: ASH, add validation on the onKeyDown before calling update password
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      const submitInfo = {
        currentPassword: passwordInfo.currentPassword,
        newPassword: passwordInfo.newPassword
      }
      // TODO haven't build api yet
      //   InviteCompany.mutate({ invitedCompany: submitInfo })
    }
  }
  const onHide = () => {
    setOpenModal(false)
    setPasswordInfo({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    })
    setIsPasswordFormatRight(true)
    setIsPasswordSameCheck(true)
    setErrorMessage('')
  }
  const onClickUpdatePassword = (e) => {
    e.preventDefault()
    setUpdate(update + 1)
    setIsPasswordFormatRight(true)
    setIsPasswordSameCheck(true)
    // validation
    checkStrongPassword()
    // if (passwordInfo.newPassword !== passwordInfo.newPasswordConfirm) {
    //
    //   setIsPasswordSameCheck(false)
    //   // display error
    //   // TODO: add error
    // }
  }
  const checkStrongPassword = () => {
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\?\=\.!@#\$%\^&\*])(?=.{8,})/

    const isPasswordSame =
      passwordInfo.newPassword === passwordInfo.newPasswordConfirm
    setErrorMessage('')
    if (!isPasswordSame) {
      setIsPasswordFormatRight(true)
      setIsPasswordSameCheck(false)
    } else {
      if (
        strongRegex.test(passwordInfo.newPassword) &&
        strongRegex.test(passwordInfo.newPasswordConfirm)
      ) {
        setIsPasswordFormatRight(true)
        submitRegisterForm()
      } else {
        setIsPasswordFormatRight(false)
      }
    }
  }

  const submitRegisterForm = () => {
    if (
      passwordInfo.currentPassword.length > 0 &&
      passwordInfo.newPassword === passwordInfo.newPasswordConfirm
    ) {
      LoginFunc.mutateAsync({
        username: userDetailsQuery.data.email,
        password: passwordInfo.currentPassword
      })
        .then((res) => {
          UpdateUserPassword.mutate({ password: passwordInfo.newPassword })
        })
        .catch((err) => {
          // console.log('Invalid current password', err.message)
          setErrorMessage(err.message)
        })
    }
  }

  const clickEyeHandler = (option) => (e) => {
    e.preventDefault()
    // hack check the first child of its parent.
    const InputElement = e.currentTarget.parentElement.firstChild
    setPasswordState((ps) => ({
      ...ps,
      [option]: {
        type: InputElement.type === 'password' ? 'text' : 'password',
        show: !passwordState[option].show
      }
    }))
  }
  return (
    <>
      <Modal show={openModal} onHide={onHide} >
        <Modal.Body>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h5 className="m-0 mb-3">Change Password</h5>

          <form>
            <Form.Group>
              <Form.Label>Current password</Form.Label>
              <InputGroup className="mb-2 input-area-to-hide-eyes">
                <Form.Control
                  type={passwordState.currentPassword.type}
                  required
                  // placeholder="cu"
                  name="current-password"
                  autoFocus={true}
                  value={passwordInfo.currentPassword}
                  onChange={(e) =>
                    setPasswordInfo((ps) => ({
                      ...ps,
                      currentPassword: '' + e.target.value
                    }))
                  }
                />
                <button
                  type="button"
                  tabIndex={-1}
                  onClick={clickEyeHandler('currentPassword')}
                  className="btn btn-outline-secondary form-inline-button"
                >
                  <i
                    className={
                      passwordState.currentPassword.show
                        ? 'fas fa-eye-slash'
                        : 'fas fa-eye'
                    }
                  ></i>
                </button>
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>New password</Form.Label>
              <InputGroup className="mb-2 input-area-to-hide-eyes">
                <Form.Control
                  type={passwordState.newPassword.type}
                  required
                  // placeholder="Email address"
                  name="password"
                  onKeyDown={onKeyDown}
                  value={passwordInfo.newPassword}
                  onChange={(e) =>
                    setPasswordInfo((ps) => ({
                      ...ps,
                      newPassword: '' + e.target.value
                    }))
                  }
                />
                <button
                  type="button"
                  tabIndex={-1}
                  onClick={clickEyeHandler('newPassword')}
                  className="btn btn-outline-secondary form-inline-button"
                >
                  <i
                    className={
                      passwordState.newPassword.show
                        ? 'fas fa-eye-slash'
                        : 'fas fa-eye'
                    }
                  ></i>
                </button>
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Re-enter new password</Form.Label>
              <InputGroup className="mb-2 input-area-to-hide-eyes">
                <Form.Control
                  type={passwordState.newPasswordConfirm.type}
                  required
                  // placeholder="Email address"
                  name="password-confirm"
                  onKeyDown={onKeyDown}
                  value={passwordInfo.newPasswordConfirm}
                  onChange={(e) =>
                    setPasswordInfo((ps) => ({
                      ...ps,
                      newPasswordConfirm: '' + e.target.value
                    }))
                  }
                />
                <button
                  type="button"
                  tabIndex={-1}
                  onClick={clickEyeHandler('newPasswordConfirm')}
                  className="btn btn-outline-secondary form-inline-button"
                >
                  <i
                    className={
                      passwordState.newPasswordConfirm.show
                        ? 'fas fa-eye-slash'
                        : 'fas fa-eye'
                    }
                  ></i>
                </button>
              </InputGroup>
            </Form.Group>
          </form>

          {/* buttons */}
          <div className="d-flex justify-content-between mt-5">
            <Button
              type="button"
              onClick={onHide}
              variant="outline-primary"
              style={{ border: 0 + 'px' }}
            >
              {UpdateUserPassword.isSuccess ? 'Close' : 'Cancel'}
            </Button>
            <Button
              type="button"
              onClick={onClickUpdatePassword}
              variant="primary"
              disabled={
                passwordInfo.currentPassword.length === 0 || (
                  passwordInfo.newPassword.length === 0 ||
                passwordInfo.newPasswordConfirm.length === 0)
              }
            >
              {UpdateUserPassword.isLoading
                ? (
                <Spinner />
                  )
                : (
                <span>Update Password</span>
                  )}
            </Button>
          </div>

          {isPasswordFormatRight
            ? (
                ''
              )
            : (

            <AlertDynamic key={update} msg={ 'Password must be 8 or more characters and contain letters (uppercase and lowercase), numbers and a symbol (?=.!@#$%^&*).'}></AlertDynamic>
              )}
          {isPasswordSameCheck
            ? (
                ''
              )
            : (

              <AlertDynamic key={update} msg={'Passwords do not match.'}></AlertDynamic>
              )}

          {errorMessage && (
            <AlertDynamic key={update} msg={errorMessage}></AlertDynamic>
          )}

          {/* TODO haven't change api so havent change Invite Company query yet */}
          {UpdateUserPassword.isSuccess && (
            <Alert variant="success" className="mb-0 mt-3 text-center">
              Update successful!
            </Alert>
          )}
          {UpdateUserPassword.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'Error: ' + UpdateUserPassword.error.message}
            </Alert>
          )}
        </Modal.Body>
        {/* {process.env.NODE_ENV !== 'production' && (<pre>companiesQuery={JSON.stringify(passwordInfo, null, 2)}</pre>)} */}
      </Modal>
    </>
  )
}

export default MyAccountChangePasswordModal
