import React from 'react'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { CreateSelect } from '../../../components/Form'
import NotificationToaster from '../../../components/NotificationToaster'
import Spinner from '../../../components/spinner'
import { useUpdatePersonDetail } from '../../../utils/api'
import utils from '../../../utils/utils'
import MyAccountChangePasswordModal from '../kitchensink/myAccountChangePasswordModal'

const MyProfile = (props) => {
  const { list, companyData } = props
  const { contactPerson, contactPersonList = [] } = companyData.data
  const updateProfile = useUpdatePersonDetail()

  const [profileForm, setProfileForm] = React.useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
    language: '',
    role: '',
    timeZone: ''
  })
  const [isShowToaster, setIsShowToaster] = React.useState(false)
  const [isShowAdminToaster, setIsShowAdminToaster] = React.useState(false)

  const [editing, setEditing] = React.useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] =
    React.useState(false)

  const handleChange = (input) => (e) => {
    if (input === 'role') {
      //
      // If there is only one admin and current user is the admin. then he can not change himself from admin role to others.
      //
      if (contactPersonList.length === 1 && contactPerson.value === list.data.id) {
        setIsShowAdminToaster(true)
        setProfileForm((ps) => ({ ...ps, [input]: profileForm.role }))
      } else {
        setProfileForm((ps) => ({ ...ps, [input]: e.target.value }))
      }
    } else {
      setProfileForm((ps) => ({ ...ps, [input]: e.target.value }))
    }
  }
  const resetForm = () => {
    setProfileForm({
      firstName: '',
      lastName: '',
      jobTitle: '',
      phoneNumber: '',
      language: '',
      role: '',
      timeZone: ''
    })
  }

  const saveUserProfile = (e) => {
    e.preventDefault()
    const original = {
      firstName: list.data.first_name || '',
      lastName: list.data.last_name || '',
      jobTitle: list.data.job_title || '',
      phoneNumber: list.data.phone_number || '',
      language: list.data.language || '',
      role: list.data.role || '',
      timeZone: list.data.time_zone || ''
    }
    const deltaData = utils.getDifferentBetweenTwoObjects(
      profileForm,
      original
    )
    if (Object.keys(deltaData).length > 0) {
      updateProfile
        .mutateAsync(deltaData)
        .then(() => setIsShowToaster(true))
        .catch(() => setIsShowToaster(true))
    } else {
      setEditing(false)
    }
  }

  React.useEffect(() => {
    if (updateProfile.isSuccess) {
      setEditing(false)
    }
  }, [updateProfile.isSuccess])

  React.useEffect(() => {
    if (editing) {
      setProfileForm({
        firstName: list.data.first_name || '',
        lastName: list.data.last_name || '',
        jobTitle: list.data.job_title || '',
        phoneNumber: list.data.phone_number || '',
        language: list.data.language || '',
        role: list.data.role || '',
        timeZone: list.data.time_zone || ''
      })
    }
  }, [editing, list])

  // drop down list option
  const roleOption =
    list.data && list.data.roleOption ? list.data.roleOption : []
  return (
    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-4">
      <div className="d-flex align-items-center justify-content-between">
        <NotificationToaster
          showToast={isShowToaster}
          setShowToasFn={setIsShowToaster}
          isSuccess={updateProfile.isSuccess}
          message={
            updateProfile.isSuccess ? 'Change successful!' : 'Failed to update!'
          }
        />
        <h4 className=" font-weight-bold inter-font-content">My Details</h4>
        {editing
          ? (
          <div>
            <Button
              className="mr-3"
              variant="outline-primary"
              onClick={() => {
                setEditing(false)
                resetForm()
              }}
            >
              Cancel
            </Button>

            <Button onClick={saveUserProfile}>
              {updateProfile.isLoading ? <Spinner /> : <span>Save</span>}
            </Button>
          </div>
            )
          : (
          <div>
            <Button
              className="mr-2"
              variant="outline-primary"
              onClick={() => setShowChangePasswordModal(true)}
            >
              Change Password
            </Button>
            <Button
              className=""
              variant="outline-primary"
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          </div>
            )}
      </div>
      <Row>
        <Col>
          {list.isLoading && (
            <div className="d-flex justify-content-center p-5">
              <Spinner />
            </div>
          )}
          {list.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'' + list.error.message}
            </Alert>
          )}
          {list.isSuccess && (
            <div>
              <Form className="pr-4 d-flex flex-column flex-grow-1 justify-content-between">
                <div>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!editing}
                      value={
                        editing ? profileForm.firstName : list.data.first_name
                      }
                      onChange={handleChange('firstName')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!editing}
                      value={
                        editing ? profileForm.lastName : list.data.last_name
                      }
                      onChange={handleChange('lastName')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Job title</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!editing}
                      value={
                        editing ? profileForm.jobTitle : list.data.job_title
                      }
                      onChange={handleChange('jobTitle')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!editing}
                      value={
                        editing
                          ? profileForm.phoneNumber
                          : list.data.phone_number
                      }
                      onChange={handleChange('phoneNumber')}
                    />
                  </Form.Group>
                </div>
              </Form>
            </div>
          )}
        </Col>
        <Col>
          {' '}
          {list.isSuccess && (
            <div className="pl-4  d-flex flex-column flex-grow-1">
              {/* <h5 className="d-flex justify-content-end my-0">1/4</h5> */}
              {/* form */}
              <Form className="d-flex flex-column flex-grow-1 justify-content-between">
                <div>
                  <Form.Group>
                    <Form.Label> Email</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={true}
                      style={{ color: 'grey' + '!important' }}
                      value={list.data.email}
                    />
                  </Form.Group>

                  <CreateSelect
                    label="Role"
                    required
                    // onChange={handleChange('country')}
                    placeholder="Select a role"
                    className="custom-select-grey"
                    value={editing ? profileForm.role : list.data.role}
                    onChange={handleChange('role')}
                    // placeholder={list.role}
                    disabled={true}
                    options={roleOption || []}
                  />

                  <CreateSelect
                    label="Language"
                    required
                    placeholder="Select a language"
                    className="custom-select-grey"
                    // onChange={handleChange('country')}
                    value={editing ? profileForm.language : list.data.language}
                    onChange={handleChange('language')}
                    disabled={!editing}
                    options={[
                      { value: 'English', label: 'English' },
                      { value: 'Chinese', label: 'Chinese' }
                    ]}
                  />
                  <CreateSelect
                    label="Timezone"
                    required
                    value={editing ? profileForm.timeZone : list.data.time_zone}
                    onChange={handleChange('timeZone')}
                    // placeholder={list.time_zone}
                    // onChange={handleChange('country')}
                    disabled={true}
                    options={[
                      { value: 'Australia/Sydney', label: 'Australia/Sydney' },
                      { value: 'TODO', label: 'TODO' }
                    ]}
                  />
                </div>
              </Form>
            </div>
          )}
        </Col>
      </Row>

      <MyAccountChangePasswordModal
        openModal={showChangePasswordModal}
        setOpenModal={setShowChangePasswordModal}
      />
      <Modal
        show={isShowAdminToaster}
        onHide={() => setIsShowAdminToaster(false)}
      >
        <Modal.Body>
          <div>
            <button
              type="button"
              className="close"
              onClick={() => setIsShowAdminToaster(false)}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <h5 className="m-0 mb-2">Can not change your role.</h5>
            <p>Your company must have at least one admin user.</p>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <Button
              type="button"
              onClick={() => setIsShowAdminToaster(false)}
              variant="outline-primary"
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MyProfile
